import * as React from "react"
import { Helmet } from "react-helmet";
import { Navigation, Content, Banner } from "../components";
import "../styles/main.scss";

import hr from "../images/hr.png";
import ss from "../images/ss.png";

// --------------------------------------------------------------------------------
// Banner
// --------------------------------------------------------------------------------
function PageBanner(): JSX.Element {
    const title = <>About us</>;

    const subtitle = <>
        <p>The Qebal People Analytics Platform is designed and developed by HR-Fundamentals, a private company based in the United Kingdom. We've been established since 2002.</p>
        <p>First and foremost, we're an HR team with a strong understanding of technology and data.</p>
    </>;

    return <Banner
        title={title}
        subtitle={subtitle}
        action={{ to: "/contact-us/", caption: "Get started" }}
    />;
}

// --------------------------------------------------------------------------------
// Introduction
// --------------------------------------------------------------------------------
function PageIntro(): JSX.Element {
    const intro = <>
        <h1>About us</h1>
        <p>The Qebal People Analytics Platform is designed and developed by HR-Fundamentals, a private company based in the United Kingdom. We've been established since 2002.</p>
        <p>First and foremost, we're an HR team with a strong understanding of technology and data.</p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1 pb-4 gy-4">
        <div className="col text-sm-center px-4 ">
            {intro}
        </div>
    </div>;
}

// --------------------------------------------------------------------------------
// Body
// --------------------------------------------------------------------------------
function PageBody(): JSX.Element {
    const body1 = <>
        <h2>Our background</h2>
        <h3>2002 &mdash; A traditional HR services company</h3>
        <p>
            Our roots are traditional HR - supporting the day-to-day of employee behaviour, manager support and operational performance.
            We helped medium sized businesses navigate their people challenges and opportunities.
        </p>

        <h3>2008 &mdash; A focus on HR technology</h3>
        <p>
            We made a strategic decision to focus on HR technology, building innovative software solutions to help our customers manage their employee performance and communications.
            We were an early adopter of cloud-based software delivery, allowing rapid delivery of new features to
            customers with offices across the world.
        </p>

        <h3>2018 &mdash; Delivering value from HR data</h3>
        <p>
            More of our clients wanted to improve their strategic decision making and better understand their data. We built new systems
            for our clients to help them deliver their strategic objectives and transformation programmes.
        </p>
        <p>
            We started the process of designing and implementing sophisticated cloud-based software to help organisations of all sizes undertand their data.
            Our early objectives were to help HR people be more strategic, support business leaders and to help them drive their organisations forward.
        </p>
    </>;

    return <div className="row pb-4 gy-4">
        <div className="col px-4">
            {body1}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Leadership
// --------------------------------------------------------------------------------
function PageLeadership(): JSX.Element {
    const block1 = <>
        <div className="px-0 py-4">
            <h3>Helen Ramsey <small>FCIPD</small></h3>
            <p>Operations Director</p>
            <img src={hr} className="rounded-circle my-0 mb-4" width="100px" height="100x" alt="" aria-label="" />

            <p>
                Helen founded the Company in 2002 and is responsible for strategy and operations. She has over 30 years of
                experience helping medium and large organisations get the most out of their employees.
            </p>
            <p>
                Helen's specialist focus within HR is data and technology, helping department heads and business
                leaders make informed decisions.
            </p>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            <h3>Stephen Simmons</h3>
            <p>Technical Director</p>
            <img src={ss} className="rounded-circle my-0 mb-4" width="100px" height="100x" alt="" aria-label="" />

            <p>
                Stephen is responsible for our technical strategy and platform architecture. He also leads the
                software development team.
            </p>
            <p>
                Stephen has over 25 years of experience delivering successful projects in the telecomms,
                pharmaceuticals and insurance sectors. He has advised start-ups, investors and growing businesses in the US and UK.
            </p>
        </div>;

    return <div className="row gx-4">
        <div className="col-12 pt-4 px-4">
            <h2>Our leadership team</h2>
        </div>
        <div className="col-12 col-sm-6 px-4">{block1}</div>
        <div className="col-12 col-sm-6 px-4">{block2}</div>
    </div>
}


const AboutUs = () => {
    return <>
        <Helmet title="Qebal / About us" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                <PageIntro />
                <PageBody />
            </div>


            <div className="qbl-block-1">
                <div className="container-lg">

                    <PageLeadership />
                </div>
            </div>
        </Content>
    </>
};

export default AboutUs;